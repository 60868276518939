import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
import PageLayout from "./../../../templates/service";
export const PageQuery_service_en_mobilemdx = graphql`
        query PageQuery_service_en_mobilemdx {
            # Required by gatsby-plugin-react-i18next
            locales: allLocale(filter: {language: {eq: "en"}, ns: {in: ["common", "service"]}}) {
                ...TranslationFragment
            }
            # Required by gatsby-plugin-image
            mdx(frontmatter:
                {
                    path: {regex: "/mobile/"},
                    language: {eq: "en"}
                }) {
                frontmatter {
                    ...MdxServiceFrontmatterFragment
                }
            }
        }
    `;
export const _frontmatter = {
  "title": "Mobile & Misc",
  "path": "/service/mobile",
  "date": "2022-10-15T00:00:00.000Z",
  "template": "Service",
  "language": "en",
  "icon": "bx-mobile-alt",
  "tags": ["developer", "android", "input"],
  "experience": [{
    "title": "Streaming: Live 3D - Mobile",
    "task": "Lead Integration / Networking",
    "tags": ["lead", "network", "developer"],
    "outcome": ["Implemented cached timeline + scrubbing", "Integration of company core framework", "Generic access to metadata", "Fluid 3D streams on mobile"],
    "link": ["https://www.gamefacebyeredivisie.nl"],
    "copyright": "Beyond Sports B.V.",
    "image": ["./images/mobile/bs-gf-app-01.jpg"]
  }, {
    "title": "3D Patient Rehabilitation",
    "task": "Lead Front-end / Pipeline",
    "tags": ["lead", "developer", "unity3d"],
    "outcome": ["Color grading via LUT", "Interactive environment features", "Bidirectional communication @ back-end", "Skin with realistic shader (pre-HDRP)"],
    "link": null,
    "image": ["./images/mobile/tq-app-client-01.jpg"],
    "copyright": "TweeQ LLC"
  }, {
    "title": "Amsterdam Ferry",
    "task": "Unity Developer / Shading / Lookdev",
    "tags": ["developer", "shading", "post-processing"],
    "outcome": ["Shaders for water & cartoon edges", "Delivered within a tight deadline", "Prefabs with animations & blendshapes", "Runtime creation of videos in Unity v4"],
    "link": ["http://depont.submarinechannel.com"],
    "image": ["./images/graphics/nmt-sub-de-pont-01.jpg"],
    "copyright": "Submarine B.V."
  }]
};
const layoutProps = {
  PageQuery_service_en_mobilemdx,
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "small-devices-big-apps",
      "style": {
        "position": "relative"
      }
    }}>{`Small devices, big apps`}<a parentName="h1" {...{
        "href": "#small-devices-big-apps",
        "aria-label": "small devices big apps permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h1>
    <p>{`With experience on the major platforms since the emergence of modern smartphones, I can help you build software for these devices.`}</p>
    <h2 {...{
      "id": "skills",
      "style": {
        "position": "relative"
      }
    }}>{`Skills`}<a parentName="h2" {...{
        "href": "#skills",
        "aria-label": "skills permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`5+ years with Android`}</li>
      <li parentName="ul">{`2+ years with iOS (mostly high-level)`}</li>
      <li parentName="ul">{`Experienced in building a wide range of apps`}</li>
      <li parentName="ul">{`Knowledge of special devices like `}<a parentName="li" {...{
          "href": "https://time.com/4173507/myo-armband-review/"
        }}>{`Myo armband`}</a>{` or `}<a parentName="li" {...{
          "href": "https://www.ultraleap.com/product/leap-motion-controller/"
        }}>{`Leap Motion controller`}</a></li>
    </ul>
    <h2 {...{
      "id": "technical",
      "style": {
        "position": "relative"
      }
    }}>{`Technical`}<a parentName="h2" {...{
        "href": "#technical",
        "aria-label": "technical permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "style": {
            "fill": "currentColor"
          }
        }}>{`
        `}<path parentName="svg" {...{
            "d": "M4.222 19.778a4.983 4.983 0 0 0 3.535 1.462 4.986 4.986 0 0 0 3.536-1.462l2.828-2.829-1.414-1.414-2.828 2.829a3.007 3.007 0 0 1-4.243 0 3.005 3.005 0 0 1 0-4.243l2.829-2.828-1.414-1.414-2.829 2.828a5.006 5.006 0 0 0 0 7.071zm15.556-8.485a5.008 5.008 0 0 0 0-7.071 5.006 5.006 0 0 0-7.071 0L9.879 7.051l1.414 1.414 2.828-2.829a3.007 3.007 0 0 1 4.243 0 3.005 3.005 0 0 1 0 4.243l-2.829 2.828 1.414 1.414 2.829-2.828z"
          }}></path>{`
        `}<path parentName="svg" {...{
            "d": "m8.464 16.95-1.415-1.414 8.487-8.486 1.414 1.415z"
          }}></path>{`
    `}</svg></a></h2>
    <ul>
      <li parentName="ul">{`Unity 3D: since v3`}</li>
      <li parentName="ul">{`Android: SDK, NDK & JNI`}</li>
      <li parentName="ul">{`Techniques like AOT are not a problem`}</li>
      <li parentName="ul">{`Internal systems such as `}<a parentName="li" {...{
          "href": "https://github.com/strangeioc/strangeioc/issues/136#issuecomment-85812549"
        }}>{`IL2CPP in Unity`}</a></li>
    </ul>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      